<p-toast></p-toast>

<div class="animated fadeIn">
  <div class="d-flex align-items-center">
    <h1>{{ 'DASHBOARD.APPLICATIONS' | translate }}</h1>
  </div>

  <div class="card">
    <p-toolbar styleClass="p-mb-4">
      <ng-template pTemplate="left">
        <button pButton pRipple label="{{ 'TOOLS.NEW' | translate }}" icon="pi pi-plus" class="p-button-success p-mr-2"
          (click)="applicationAdd()"></button>
        
        <p-multiSelect [options]="columns" [(ngModel)]="selectedColumns" optionLabel="title" [maxSelectedLabels]="0"
          selectedItemsLabel="{0} {{ 'TOOLS.COLUMNSSELECTED' | translate }}" [style]="{minWidth: '200px'}"
          placeholder="{{ 'TOOLS.CHOOSECOLUMNS' | translate }}"></p-multiSelect>
      </ng-template>

      <ng-template pTemplate="right">
        <button type="button" pButton pRipple *ngIf="applications.length > 0" icon="pi pi-filter-slash"
          class="p-button-help p-mr-2" pTooltip="{{ 'TOOLS.CLEARFILTERS' | translate }}" tooltipPosition="bottom"
          (click)="dt.clear()"></button>
        <button type="button" pButton pRipple *ngIf="applications.length > 0" icon="pi pi-file-o"
          class="p-button-primary p-mr-2" pTooltip="{{ 'TOOLS.EXPORTCSV' | translate }}" tooltipPosition="bottom"
          (click)="exportCsv()"></button>
        <button type="button" pButton pRipple *ngIf="applications.length > 0" icon="pi pi-file-excel"
          class="p-button-success p-mr-2" pTooltip="{{ 'TOOLS.EXPORTXLS' | translate }}" tooltipPosition="bottom"
          (click)="exportExcel()"></button>
        <button type="button" pButton pRipple *ngIf="applications.length > 0" icon="pi pi-file-pdf"
          class="p-button-warning p-mr-2" pTooltip="{{ 'TOOLS.EXPORTPDF' | translate }}" tooltipPosition="bottom"
          (click)="exportPdf()"></button>
        <button type="button" pButton pRipple *ngIf="applications.length > 0" icon="pi pi-refresh"
          class="p-button-danger p-mr-2" pTooltip="{{ 'TOOLS.REFRESHLIST' | translate }}" tooltipPosition="bottom"
          (click)="refresh()"></button>
       
      </ng-template>
    </p-toolbar>

    <p-table #dt [value]="applications" [rows]="10" [rowsPerPageOptions]="[10,25,50,100]" [paginator]="true"
      [columns]="selectedColumns" [resizableColumns]="false" [rowHover]="true"
      dataKey="id" [loading]="isLoading" stateStorage="local" stateKey="applications"
      styleClass="p-datatable-striped p-datatable-gridlines p-datatable-responsive"
      [globalFilterFields]="['id','name','description','background','activityStyleNotFocused',
      'tabBar','activityStyle','logo','secondaryColor','primaryColor','offerId','tabIconNotFocused',
      'tabIconFocused','addRemoveObjectifColor','objectifBackgroundColor','progressBackgroundColor',
      'progressBackgroundColor','progressValueColor','ajourdHisto','isActive','profileColor',
      'createdAt','creatorUser.username','modifiedAt','modifierUser.username']"
      currentPageReportTemplate="{{ 'TOOLS.PAGING' | translate }}" [showCurrentPageReport]="true" selectionMode="multiple">
      <ng-template pTemplate="caption">
        <div class="p-d-flex p-ai-center p-jc-between">
          <h5 class="p-m-0">{{ 'APPLICATION.MANAGEAPPLICATIONS' | translate }}</h5>
        </div>
      </ng-template>
      <ng-template pTemplate="header" let-columns>
        <tr *ngIf="columns.length > 0">
          <ng-container *ngFor="let col of columns">
            <th *ngIf="col.field !== 'actions'" [hidden]="!col.show" pSortableColumn="{{col.sortField}}">
              <div class="p-d-flex p-jc-between p-ai-center">
                {{ col.title }}
                <p-sortIcon field="name"></p-sortIcon>
                <p-columnFilter type="text" [field]="col.field" display="menu" *ngIf="col.type === 'textValue'">
                </p-columnFilter>
                <p-columnFilter type="text" [field]="col.field" display="menu" *ngIf="col.type === 'arrayValue'">
                </p-columnFilter>
                <p-columnFilter type="numeric" [field]="col.field" display="menu" *ngIf="col.type === 'numericValue'">
                </p-columnFilter>
                <p-columnFilter [field]="col.filterField" display="menu" *ngIf="col.type === 'linkValue'" matchMode="in"
                  [showMatchModes]="false" [showOperator]="false" [showAddButton]="false">
                  <ng-template pTemplate="header">
                    <div class="p-px-3 p-pt-3 p-pb-0">
                      <span class="p-text-bold">{{ col.title }} Picker</span>
                    </div>
                  </ng-template>
                  <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                    <p-multiSelect [ngModel]="value" [options]="col.filterData" placeholder="Any"
                      (onChange)="filter($event.value)" optionLabel="{{col.displayField}}" optionValue="id">
                      <ng-template let-option pTemplate="item">
                        <div class="p-multiselect-representative-option">
                          <span class="p-ml-1">{{option[col.displayField]}}</span>
                        </div>
                      </ng-template>
                    </p-multiSelect>
                  </ng-template>
                </p-columnFilter>
                <p-columnFilter type="date" [field]="col.field" display="menu" *ngIf="col.type === 'dateValue'">
                </p-columnFilter>
                <p-columnFilter type="boolean" [field]="col.field" display="menu" *ngIf="col.type === 'booleanValue'">
                </p-columnFilter>
                <p-columnFilter [field]="col.field" display="menu" *ngIf="col.type === 'enumValue'" matchMode="equals">
                  <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                    <p-dropdown [ngModel]="value" [options]="col.filterData" (onChange)="filter($event.value)"
                      placeholder="Any">
                      <ng-template let-option pTemplate="item">
                        <span [class]="'p-2 bg-' + option.css">{{ option.label | translate }}</span>
                      </ng-template>
                    </p-dropdown>
                  </ng-template>
                </p-columnFilter>
              </div>
            </th>
            <th *ngIf="col.field === 'actions'">{{ col.title }}</th>
          </ng-container>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-columns="selectedColumns" let-application>
        <tr *ngIf="selectedColumns.length > 0">

          <td *ngFor="let col of selectedColumns" [hidden]="!col.show">
            <ng-container *ngIf="col.field === 'actions'">
              <button pButton pRipple icon="pi pi-pencil" pTooltip="{{ 'ACTIONS.EDIT' | translate }}"
                class="p-button-rounded p-button-success p-mr-2 p-mt-1 p-mb-1" (click)="applicationEdit(application)"></button>
              <button pButton pRipple icon="pi pi-trash" pTooltip="{{ 'ACTIONS.DELETE' | translate }}"
                class="p-button-rounded p-button-danger p-mr-2 p-mt-1 p-mb-1" (click)="applicationDelete(application)"></button>
              <button pButton pRipple icon="pi pi-ellipsis-h" pTooltip="{{ 'ACTIONS.DETAILS' | translate }}"
                class="p-button-rounded p-button-warning p-mr-2 p-mt-1 p-mb-1" (click)="applicationDetails(application)"></button>
            </ng-container>
            <ng-container *ngIf="col.field !== 'actions'">
              <app-table-cell-value [value]="application[col.field]" [column]="col"></app-table-cell-value>
            </ng-container>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="summary">
        <div class="p-d-flex p-ai-center p-jc-between">
          {{ 'ACTIONS.INTOTAL' | translate }} {{applications ? applications.length : 0 }} {{ 'APPLICATION.APPLICATION' | translate }}.
        </div>
      </ng-template>
    </p-table>
  </div>

  <p-toast position="bottom-center" key="postDelete" (onClose)="onDeleteReject()" [baseZIndex]="5000">
    <ng-template let-message pTemplate="message">
      <div class="p-flex p-flex-column" style="flex: 1">
        <div class="text-center">
          <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i>
          <h4>{{message.summary}}</h4>
          <p>{{message.detail}}</p>
        </div>
        <div class="row">
          <button type="button" pButton (click)="onDeleteConfirm()" label="Yes" class="col-5 p-button-success"></button>
          <div class="col-2"></div>
          <button type="button" pButton (click)="onDeleteReject()" label="No" class="col-5 p-button-secondary"></button>
        </div>
      </div>
    </ng-template>
  </p-toast>
</div>
