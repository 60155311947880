import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { MessageService, ConfirmationService } from 'primeng/api';
import { CommonService } from '../../../../services/common/common.service';
import { ApplicationsDataService, ApplicationsColumnsService, CategoriesDataService } from '../../../services';
import { Application, ApplicationsResult, CategoriesResult } from '../../../interfaces';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { EditorService } from '../../../../services/editor/editor.service';

@Component({
  selector: 'app-post-form',
  templateUrl: './application-form.component.html',
  styleUrls: ['./application-form.component.scss'],
  providers: [MessageService, ConfirmationService, DynamicDialogRef, DynamicDialogConfig],
})
export class ApplicationFormComponent implements OnInit {

  public application: Application = {};
  public applicationForm: FormGroup = new FormGroup({});
  public applications: Application[] = [];
  public isLoading: boolean = false;
  public locale: string = (localStorage.language)?localStorage.language:'en';
  public redirect = true;
  public disableSubmit = false;
  public enableFormAlert = true;
  public categories:any;
  selectedFile: File | null;

  public Editor = ClassicEditor;
  categoryValue: any;
  formData = new FormData();

  constructor(
    private translate: TranslateService,
    public common: CommonService,
    private router: Router,
    private messageService: MessageService,
    private dynamicDialogConfig: DynamicDialogConfig,
    private dynamicDialogRef: DynamicDialogRef,
    private applicationsDataService: ApplicationsDataService,
    public applicationsColumnsService: ApplicationsColumnsService,
    public editorService: EditorService,
    public categoriesDataService: CategoriesDataService
  ) {
    this.selectedFile = null; // Initialize it to a default value, such as null
  }

  ngOnInit(): void {
    this.getCategories();
    if (this.dynamicDialogConfig.data && typeof this.dynamicDialogConfig.data.post !== 'undefined') {
      this.application = this.dynamicDialogConfig.data.post;
    }
  if (typeof this.application.id === 'undefined' || this.application.id === null) {
      this.application.id = null;
      this.application.isActive = false;
  }
    
    this.getApplications();
    this.prepareFrom();
  }


  getCategories(){
    this.categoriesDataService.getCategories().subscribe((response: CategoriesResult|any) => {
      this.isLoading = false;
      if (response !== null) {
        this.categories = response.success.data;
      }
    }, (error: any) => {;
     
    });
  }

  getApplications(): void {
    if (this.dynamicDialogConfig.data && typeof this.dynamicDialogConfig.data.posts !== 'undefined' && this.dynamicDialogConfig.data.posts.length > 0) {
      this.applications = this.dynamicDialogConfig.data.posts;
      return;
    }
    this.applicationsDataService.getApplications().subscribe((response: ApplicationsResult|any) => {
      this.isLoading = false;
      if (response !== null) {
        this.applications = response.results;
      }
    });
  }

  prepareFrom(): void {
    this.applicationForm = new FormGroup({
      id: new FormControl(this.application.id, []),
      name: new FormControl(this.application.name, []),
      logoLeft: new FormControl(this.application.logoLeft, []),
      logoRight: new FormControl(this.application.logoRight, []),

      description: new FormControl(this.application.description, []),
      background: new FormControl(this.application.background, []),
      primaryColor: new FormControl(this.application.primaryColor, []),
      tabBar: new FormControl(this.application.tabBar, []),
      secondaryColor: new FormControl(this.application.secondaryColor, []),
      activityStyleNotFocused: new FormControl(this.application.activityStyleNotFocused, []),

     
    });
  }

  onChange(e:any) {
    this.categoryValue = e.value;
  }

  list() {
    this.router.navigate(['/posts']);
  }

  showErrors() {
      const invalidFields: string[] = [];
      Object.keys(this.applicationForm.controls).forEach((field: any) => {
        const control = this.applicationForm.get(field);
        if (control !== null) {
          control.markAsTouched({ onlySelf: true });
          if (control.invalid) {
            invalidFields.push(field);
          }
        }
      });
  }

  submitForm(redirect: boolean) {
    this.redirect = redirect;
    if (!this.applicationForm.valid) {
      this.showErrors();
    } else {
      this.saveData(redirect);
    }
  }

  saveData(redirect: boolean) {
    this.application = this.applicationForm.value;
    this.disableSubmit = true;
    this.formData.append('name', this.applicationForm ? this.applicationForm.value.name : null);
    this.formData.append('description', this.applicationForm ? this.applicationForm.value.description : null);

    this.formData.append('primaryColor', this.applicationForm ? this.applicationForm.value.primaryColor : null);
    this.formData.append('activityStyle', this.applicationForm ? this.applicationForm.value.primaryColor : null);
    this.formData.append('addRemoveObjectifColor', this.applicationForm ? this.applicationForm.value.primaryColor : null);
    this.formData.append('activeGoalProgresseColor', this.applicationForm ? this.applicationForm.value.primaryColor : null);
    this.formData.append('description', this.applicationForm ? this.applicationForm.value.primaryColor : null);
    this.formData.append('ajourdHisto', this.applicationForm ? this.applicationForm.value.primaryColor : null);



    this.formData.append('secondaryColor', this.applicationForm ? this.applicationForm.value.secondaryColor : null);
    this.formData.append('tabBar', this.applicationForm ? this.applicationForm.value.tabBar : null);

    this.formData.append('activityStyleNotFocused', this.applicationForm ? this.applicationForm.value.activityStyleNotFocused : null);
    this.formData.append('objectifBackgroundColor', this.applicationForm ? this.applicationForm.value.tabBar : null);
    this.formData.append('progressBackgroundColor', this.applicationForm ? this.applicationForm.value.tabBar : null);
    this.formData.append('progressValueColor', this.applicationForm ? this.applicationForm.value.tabBar : null);
    this.formData.append('profileColor', this.applicationForm ? this.applicationForm.value.tabBar : null);
    this.formData.append('tabIconNotFocused', this.applicationForm ? this.applicationForm.value.tabBar : null);
    this.formData.append('tabIconFocused', this.applicationForm ? this.applicationForm.value.tabBar : null);


   
    if (this.application.id === null) {
      this.add(redirect);
    } else {
      this.update(redirect);
    }
    return false;
  }

  update(redirect: boolean) {
    this.applicationsDataService.updateApplication(this.formData, this.applicationForm.value.id).subscribe((data: any) => {
      this.disableSubmit = false;
      if (redirect && typeof this.dynamicDialogConfig.data !== 'undefined') {
        this.dynamicDialogRef.close({refresh: true});
      } else if (redirect) {
        this.list();
      }
    }, (error: any) => {
      this.disableSubmit = false;
      console.warn(error);
    });
  }

  add(redirect: boolean) {
    this.applicationsDataService.addApplication(this.formData).subscribe((data: any) => {
        this.disableSubmit = false;
        if (redirect && typeof this.dynamicDialogConfig.data !== 'undefined') {
          this.dynamicDialogRef.close({refresh: true});
        } else if (redirect) {
          this.list();
        }
      }, (error: any) => {
        this.disableSubmit = false;
        console.warn(error);
    });
  }

  uploadLogoLeft(event: any): void {
    console.log('event',  event.target.files[0])
    this.selectedFile = event.target.files[0] as File;
    if (this.selectedFile) {
      this.formData.append('logoLeft', this.selectedFile);
    }
  }
  uploadLogoRight(event: any): void {
    console.log('event',  event.target.files[0])
    this.selectedFile = event.target.files[0] as File;
    if (this.selectedFile) {
      this.formData.append('logoRight', this.selectedFile);
    }
  }

  uploadBackground(event: any): void {
    console.log('event',  event.target.files[0])
    this.selectedFile = event.target.files[0] as File;
    if (this.selectedFile) {
      this.formData.append('background', this.selectedFile);
    }
  }
  setColor(event: any) {
    const control = this.applicationForm.get('tabBar');
    if (control !== null) {
      control.setValue(event.value);
    }
  }

  setColorActivityStyleNotFocused(event: any) {
    const control = this.applicationForm.get('activityStyleNotFocused');
    if (control !== null) {
      console.log('control', event.value)

      control.setValue(event.value);
    }
  }

}

@Component({
  selector: 'app-post-form',
  templateUrl: './application-form.component.html',
  styleUrls: ['./application-form.component.scss'],
  providers: [MessageService, ConfirmationService],
})
export class DialogApplicationFormComponent extends ApplicationFormComponent {
}

