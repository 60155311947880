<div class="container-fluid container-fullw">
  <div class="p-fluid p-grid">
    <div class="p-col-12">
      <div>
        <p-fieldset [toggleable]="true">
          <ng-template pTemplate="header">
            <span *ngIf="application.id === null">{{ 'APPLICATION.NEWAPPLICATION' | translate }}</span>
            <span *ngIf="application.id !== null">{{ 'APPLICATION.EDITAPPLICATION' | translate }}</span>
          </ng-template>
          <form pFocusTrap name="applicationForm" id="applicationForm" novalidate (ngSubmit)="submitForm(true)" [formGroup]="applicationForm" >
            <input type="hidden" name="id" id="applicationId" formControlName="id" />
            <div class="p-fluid p-grid">
            
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="fas fa-tags"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="applicationName" formControlName="name" type="text" pInputText [required]="false"> 
                      <label for="applicationName" class="">{{ 'CONTENT.NAME' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="fas fa-tags"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="applicationDescription" formControlName="description" type="text" pInputText [required]="false"> 
                      <label for="applicationDescription" class="">{{ 'CONTENT.DESCRIPTION' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
            
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="fas fa-tags"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="applicationPrimaryColor" formControlName="primaryColor" type="text" pInputText [required]="false"> 
                      <label for="applicationPrimaryColor" class="">{{ 'Primary Color' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->

              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="fas fa-tags"></i>
                    </span>
                    <span class="p-float-label">
                      <input id="applicationSecondaryColor" formControlName="secondaryColor" type="text" pInputText [required]="false"> 
                      <label for="applicationSecondaryColor" class="">{{ 'Secondary Color' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            
            <div class="p-fluid p-grid">
              
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  
                  <label for="logoUpload" class="p-text-bold">Logo Left</label>

                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-image"></i>
                    </span>

                    <input type="file"  pButton icon="pi pi-folder" styleClass="p-button-info"  class="w-100" (change)="uploadLogoLeft($event)" accept="image/*" />
                  </div>
                </div>

              </div><!--/p-col-6-->
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <label class="p-text-bold">Logo Right</label>

                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-image"></i>
                    </span>

                    <input type="file"  pButton pRipple icon="pi pi-folder" styleClass="p-button-info"  class="w-100" (change)="uploadLogoRight($event)" accept="image/*" />
                  </div>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
            <div class="p-fluid p-grid">
              
             

                <div class="p-field p-col-12">
                  <label for="backgroundUpload" class="p-text-bold">Background</label>

                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-image"></i>
                    </span>

                    <input type="file"  pButton pRipple icon="pi pi-folder" styleClass="p-button-info"  class="w-100" (change)="uploadBackground($event)" accept="image/*" />
                  </div>
                </div>

            </div><!--/p-fluid p-grid-->
        
            <div class="p-fluid p-grid">
            
              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-palette"></i>
                    </span>
                    <span class="p-inputgroup-addon" style="max-width: 1em;">
                      <p-colorPicker (onChange)="setColor($event)" formControlName="tabBar"></p-colorPicker>
                    </span>
                    <span class="p-float-label">
                      <input id="applicationTabBar" formControlName="tabBar" type="text" pInputText [required]="false"> 
                      <label for="applicationTabBar" class="">{{ 'Tab Bar' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->

              <div class="p-col-6">

                <div class="p-field p-col-12">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                      <i class="icon-palette"></i>
                    </span>
                    <span class="p-inputgroup-addon" style="max-width: 1em;">
                      <p-colorPicker (onChange)="setColorActivityStyleNotFocused($event)" formControlName="activityStyleNotFocused"></p-colorPicker>
                    </span>
                    <span class="p-float-label">
                      <input id="applicationActivityStyleNotFocused" formControlName="activityStyleNotFocused" type="text" pInputText [required]="false"> 
                      <label for="applicationActivityStyleNotFocused" class="">{{ 'Activity Style Not Focused' | translate }}</label>
                    </span>
                  </div>
                </div>

              </div><!--/p-col-6-->
            </div><!--/p-fluid p-grid-->
         

            <div class="p-fluid p-grid">

              <div class="p-col-12">
                <button type="button" (click)="submitForm(true)" class="btn btn-primary pull-right" [disabled]="disableSubmit"> <!--  [disabled]="!postForm.valid || " -->
                  <i class="fas fa-spinner fa-spin" *ngIf="disableSubmit && redirect"></i>
                  <i class="icon-save" *ngIf="!(disableSubmit && redirect)"></i>
                  <span class="p-ml-1">{{ 'ACTIONS.SAVE' | translate }}</span>
                </button>
              </div>
            </div>
          </form>
        </p-fieldset>
        <div class="p-fluid p-grid">
          <div class="col-sm-12 p-col-4 mb-2">
          </div>
          <div class="col-sm-12 p-col-4 mb-2 text-center">
          </div>
          <div class="col-sm-12 p-col-4 mb-2">
          </div>
        </div>
      </div>
    </div><!--/p-col-12-->
  </div><!--/p-fluid p-grid-->
  <p-scrollTop target="parent"></p-scrollTop>
</div><!--/container-fluid container-fullw-->
<!-- end: FORM -->

