import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { MessageService, ConfirmationService, PrimeNGConfig } from 'primeng/api';
import { Title } from '@angular/platform-browser';
import { NavigationService } from '../../../services/navigation/navigation.service';
import { ToolsService } from '../../../services/tools/tools.service';
import { Column } from '../../interfaces/column.interface';
import { ApplicationsDataService, ApplicationsColumnsService } from '../../services';
import { DialogApplicationFormComponent } from './application-form/application-form.component';
import { DialogApplicationDetailsComponent } from './application-details/application-details.component';
import { Application, ApplicationsResult } from '../../interfaces';

@Component({
  selector: 'app-posts',
  templateUrl: './applications.component.html',
  styleUrls: ['./applications.component.scss'],
  providers: [MessageService, ConfirmationService, DialogService],
})
export class ApplicationsComponent implements OnInit, OnDestroy {

  private applicationAddDialog: DynamicDialogRef = new DynamicDialogRef();
  private applicationEditDialog: DynamicDialogRef = new DynamicDialogRef();
  private applicationDetailsDialog: DynamicDialogRef = new DynamicDialogRef();
  public applicationsToDelete: Application = {};
  public applications: Application[] = [];
  public columns: Column[] = [];
  public isLoading: boolean = false;
  public isInitialized: boolean = false;
  private _selectedColumns: Column[] = [];

  constructor(
    private translate: TranslateService,
    private navigation: NavigationService,
    private router: Router,
    private dialogService: DialogService,
    private messageService: MessageService,
    private primengConfig: PrimeNGConfig,
    private titleService: Title,
    private applicationsDataService: ApplicationsDataService,
    private applicationsColumnsService: ApplicationsColumnsService,
  ) {
  }

  ngOnInit(): void {
    this.isInitialized = true;
    this.titleService.setTitle('Applications - Management');
    this.primengConfig.ripple = true;
    this.getApplications();
  }

  ngOnDestroy(): void {
    this.isInitialized = false;
  }

  getApplications(): void {
    this.isLoading = true;
    if (!this.isInitialized) {
      return;
    }
    this.applicationsDataService.getApplications().subscribe((response: ApplicationsResult|any) => {
      this.isLoading = false;
      if (response !== null) {
        this.setColumns();
        this.applications = response.success.data;
      }
    }, (error: any) => {;
      this.isLoading = false;
      this.messageService.add({severity: 'error', summary: this.translate.instant('UI.ERROR'), detail: this.translate.instant('UI.ApplicationSNOTLOADED')});
    });
  }

  setColumns() {
    if (this.columns.length > 0) {
      return;
    }
    this.columns = this.applicationsColumnsService.getColumns();
    for (let i = 0; i < this.columns.length; i++) {
      if (this.columns[i].show) {
        this._selectedColumns.push(this.columns[i]);
      }
    }
  }

  @Input()
  get selectedColumns(): any[] {
      return this._selectedColumns;
  }

  set selectedColumns(val: any[]) {
      // restore original order
      this._selectedColumns = this.columns.filter((col: any) => val.includes(col));
      this.updateColumns();
  }

  updateColumns() {
    for (let i = 0; i < this.columns.length; i++) {
      const show = this._selectedColumns.includes(this.columns[i]);
      this.columns[i].show = show;
      this.navigation.setParamValue('posts.' + this.columns[i].field + '.showFiled', show);
    }
  }

  exportPdf() {
    ToolsService.exportPdf(this.applications, this.columns, 'Applications');
  }

  exportExcel() {
    ToolsService.exportExcel(this.applications, this.columns, 'Applications');
  }

  exportCsv() {
    ToolsService.exportCsv(this.applications, this.columns, 'Applications');
  }

  refresh() {
    this.getApplications();
  }

  applicationAdd(): void {
    if (!this.navigation.getParamValue('posts.addInDialog', true)) {
      this.router.navigate(['/applications/add']);
      return;
    }
    this.applicationAddDialog = this.dialogService.open(DialogApplicationFormComponent, {
      header: '', // this.translate.instant('NEWApplication'),
      width: '80%',
      contentStyle: { 'height': '800px', overflow: 'auto' },
      baseZIndex: 1000,
      data: {
        mode: 'add',
        post: {id: null},
      },
    });

    this.applicationAddDialog.onClose.subscribe((response: any) => {
      if (response && response.refresh) {
        this.messageService.clear();
        this.messageService.add({severity: 'success', summary: 'Success', detail: 'Application added'});
        this.getApplications();
      }
    });
  }

  applicationEdit(application: Application): void {
    if (!this.navigation.getParamValue('posts.editInDialog', true)) {
      this.router.navigate(['/applications/edit/' + application.id]);
      return;
    }
    this.applicationEditDialog = this.dialogService.open(DialogApplicationFormComponent, {
      header: '', // this.translate.instant('EDITApplication') + ' ' + Application.title,
      width: '70%',
      contentStyle: { 'height': '800px', overflow: 'auto' },
      baseZIndex: 1000,
      data: {
        mode: 'edit',
        post: application,
       // users: this.postsColumnsService.users,
      },
    });

    this.applicationEditDialog.onClose.subscribe((response: any) => {
      if (response && response.refresh) {
        this.messageService.clear();
        this.messageService.add({severity: 'success', summary: 'Success', detail: 'application updated'});
        this.getApplications();
      }
    });
  }

  applicationDetails(application: Application): void {
    if (!this.navigation.getParamValue('posts.detailsInDialog', true)) {
      this.router.navigate(['/applications/details/' + application.id]);
      return;
    }
    this.applicationDetailsDialog = this.dialogService.open(DialogApplicationDetailsComponent, {
      width: '70%',
      contentStyle: { 'height': '800px', overflow: 'auto' },
      baseZIndex: 1000,
      data: {
        post: application,
      }
    });
  }

  applicationDelete(application: Application): void {
    this.applicationsToDelete = application;
    this.messageService.clear();
    this.messageService.add({
      key: 'postDelete',
      sticky: true,
      severity: 'warn',
      summary: 'Are you sure you want delete ' + application.name + ' ?',
      detail: 'Confirm to proceed'
    });
  }

  onDeleteConfirm(): void {
    this.applicationsDataService.deleteApplication(this.applicationsToDelete).subscribe((res: any) => {
      this.messageService.clear();
      this.messageService.add({severity: 'success', summary: 'Success', detail: 'Application deleted'});
      this.getApplications();
    }, (error: any) => {
      this.messageService.clear();
      this.messageService.add({severity: 'error', summary: 'Error', detail: 'application not deleted'});
    });
  }

  onDeleteReject(): void {
    this.applicationsToDelete = {};
    this.messageService.clear('postDelete');
  }

}
