import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Application, ApplicationsResult } from '../interfaces';
import { AuthService } from '../../services/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class ApplicationsDataService {

  private uri: string = `${environment.apiUrl}list-application`;
  private headers:any ;

  constructor(
    private http: HttpClient
  ) {
    const jsonAuth = localStorage.getItem(AuthService.ATUH_STORAGE_KEY);
    if (jsonAuth) {
      const token = JSON.parse(jsonAuth)?.token;
      if (token) {
        this.headers = new HttpHeaders({
          enctype: 'multipart/form-data',
          authorization: 'bearer ' + token,
        });
      } 
    }
  }

  getApplications(): Observable<ApplicationsResult|any> {
    return this.http.get(this.uri + "/all-applications");
  }

  addApplication(data: any): Observable<Application|any> {
    return this.http.post(this.uri + '/add', data, { headers :this.headers });
  }

  updateApplication(data: any,id:any): Observable<Application|any> {
    return this.http.post(this.uri + '/update'+ `/${id}`, data, { headers :this.headers });
  }

  deleteApplication(data: Application): Observable<null|any> {
    return this.http.put(this.uri + "/update_active" + `/${data.id}`, "");
  }

}
