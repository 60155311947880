import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NavigationService } from '../../services/navigation/navigation.service';
import { Column } from '../interfaces/column.interface';

@Injectable({
  providedIn: 'root'
})
export class ApplicationsColumnsService {


  constructor(
    private translate: TranslateService,
    private navigation: NavigationService,
  ) {
  }
 
  getColumns(): Column[] {
    return [
      { field: 'logoLeft', sortField: 'logoLeft', title: this.translate.instant('Logo Left'), show: (this.navigation.getParamValue('applications.imagePath.showFiled', true) && true), displayInList: true, type: 'imgValue'},
      { field: 'logoRight', sortField: 'logoRight', title: this.translate.instant('Logo Right'), show: (this.navigation.getParamValue('applications.imagePath.showFiled', true) && true), displayInList: true, type: 'imgValue'},

      { field: 'name', sortField: 'name', title: this.translate.instant('CONTENT.NAME'), show: (this.navigation.getParamValue('applications.name.showFiled', true) && true), displayInList: true, type: 'textValue' },
      { field: 'description', sortField: 'description', title: this.translate.instant('CONTENT.DESCRIPTION'), show: (this.navigation.getParamValue('applications.description.showFiled', true) && true), displayInList: true, type: 'textValue' },
      { field: 'background', sortField: 'background', title: this.translate.instant('BACKGROUND'), show: (this.navigation.getParamValue('applications.background.showFiled', true) && true), displayInList: true, type: 'imgValue' },
      { field: 'activityStyleNotFocused', sortField: 'activityStyleNotFocused', title: this.translate.instant('activity Style Not Focused'), show: (this.navigation.getParamValue('applications.activityStyleNotFocused.showFiled', true) && true), displayInList: true, type: 'colorValue' },
      { field: 'tabBar', sortField: 'tabBar', title: this.translate.instant('tab Bar'), show: (this.navigation.getParamValue('applications.tabBar.showFiled', true) && true), displayInList: true, type: 'colorValue' },
      { field: 'activityStyle', sortField: 'activityStyle', title: this.translate.instant('activity Style'), show: (this.navigation.getParamValue('applications.activityStyle.showFiled', true) && true), displayInList: true, type: 'colorValue' },
      { field: 'secondaryColor', sortField: 'secondaryColor', title: this.translate.instant('Secondary Color'), show: (this.navigation.getParamValue('applications.secondaryColor.showFiled', false) && true), displayInList: true, type: 'colorValue'},
      { field: 'primaryColor', sortField: 'primaryColor', title: this.translate.instant('Primary Color'), show: (this.navigation.getParamValue('applications.primaryColor.showFiled', false) && true), displayInList: true, type: 'colorValue'},
     
      { field: 'isActive', sortField: 'isActive', title: this.translate.instant('CONTENT.SATUTS'), show: (this.navigation.getParamValue('applications.isActive.showFiled', false) && true), displayInList: true, type: 'colorValue'},
      { field: 'offerId', sortField: 'offerId', title: this.translate.instant('offer Id'), show: (this.navigation.getParamValue('applications.offerId.showFiled', false) && true), displayInList: true, type: 'textValue'},
      { field: 'tabIconNotFocused', sortField: 'tabIconNotFocused', title: this.translate.instant('tab Icon Not Focused'), show: (this.navigation.getParamValue('applications.tabIconNotFocused.showFiled', false) && true), displayInList: true, type: 'colorValue'},
      { field: 'tabIconFocused', sortField: 'tabIconFocused', title: this.translate.instant('Primary Color'), show: (this.navigation.getParamValue('applications.tabIconFocused.showFiled', false) && true), displayInList: true, type: 'colorValue'},
      { field: 'addRemoveObjectifColor', sortField: 'addRemoveObjectifColor', title: this.translate.instant('tab Icon Focused'), show: (this.navigation.getParamValue('applications.addRemoveObjectifColor.showFiled', false) && true), displayInList: true, type: 'colorValue'},
      { field: 'objectifBackgroundColor', sortField: 'objectifBackgroundColor', title: this.translate.instant('objectif Background Color'), show: (this.navigation.getParamValue('applications.objectifBackgroundColor.showFiled', false) && true), displayInList: true, type: 'colorValue'},
      { field: 'progressBackgroundColor', sortField: 'progressBackgroundColor', title: this.translate.instant('progress Background Color'), show: (this.navigation.getParamValue('applications.progressBackgroundColor.showFiled', false) && true), displayInList: true, type: 'colorValue'},
      { field: 'activeGoalProgresseColor', sortField: 'activeGoalProgresseColor', title: this.translate.instant('active Goal Progresse Color'), show: (this.navigation.getParamValue('applications.activeGoalProgresseColor.showFiled', false) && true), displayInList: true, type: 'colorValue'},
      { field: 'progressValueColor', sortField: 'progressValueColor', title: this.translate.instant('Primary Color'), show: (this.navigation.getParamValue('applications.progressValueColor.showFiled', false) && true), displayInList: true, type: 'colorValue' },
      { field: 'ajourdHisto', sortField: 'ajourdHisto', title: this.translate.instant('ajourd Histo'), show: (this.navigation.getParamValue('applications.ajourdHisto.showFiled', false) && true), displayInList: true, type: 'colorValue' },
      { field: 'profileColor', sortField: 'profileColor', title: this.translate.instant('profile Color'), show: (this.navigation.getParamValue('applications.profileColor.showFiled', false) && true), displayInList: true, type: 'colorValue' },
      { field: 'createdAt', sortField: 'createdAt', title: this.translate.instant('created At'), show: (this.navigation.getParamValue('applications.createdAt.showFiled', false) && true), displayInList: true, type: 'dateValue', valueFormatter: this.translate.instant('DATETIMEFORMAT') },

      { field: 'updatedAt', sortField: 'updatedAt', title: this.translate.instant('updated At'), show: (this.navigation.getParamValue('applications.updatedAt.showFiled', false) && true), displayInList: true, type: 'dateValue', valueFormatter: this.translate.instant('DATETIMEFORMAT') },

     
     
     
      { field: 'actions', title: this.translate.instant('CONTENT.ACTIONS'), type: 'static', show: true, displayInList: true, sortField: null },
    ];
  }

}
