
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// import { FileSaverModule } from 'ngx-filesaver';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpLoaderFactory } from '../factories/http-loader.factory';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
// <primeng-modules>
import { ConfirmationService, MessageService } from 'primeng/api';
import { AccordionModule } from 'primeng/accordion';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { CarouselModule } from 'primeng/carousel';
import { ChartModule } from 'primeng/chart';
import { CheckboxModule } from 'primeng/checkbox';
import { ChipsModule } from 'primeng/chips';
import { CodeHighlighterModule } from 'primeng/codehighlighter';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ColorPickerModule } from 'primeng/colorpicker';
import { ContextMenuModule } from 'primeng/contextmenu';
import { DataViewModule } from 'primeng/dataview';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { DialogService, DynamicDialogModule } from 'primeng/dynamicdialog';
import { EditorModule } from 'primeng/editor';
import { FieldsetModule } from 'primeng/fieldset';
import { FileUploadModule } from 'primeng/fileupload';
import { FocusTrapModule } from 'primeng/focustrap';
import { FullCalendarModule } from 'primeng/fullcalendar';
import { GalleriaModule } from 'primeng/galleria';
import { InplaceModule } from 'primeng/inplace';
import { InputMaskModule } from 'primeng/inputmask';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { LightboxModule } from 'primeng/lightbox';
import { ListboxModule } from 'primeng/listbox';
import { MegaMenuModule } from 'primeng/megamenu';
import { MenuModule } from 'primeng/menu';
import { MenubarModule } from 'primeng/menubar';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { MultiSelectModule } from 'primeng/multiselect';
import { OrderListModule } from 'primeng/orderlist';
import { OrganizationChartModule } from 'primeng/organizationchart';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PaginatorModule } from 'primeng/paginator';
import { PanelModule } from 'primeng/panel';
import { PanelMenuModule } from 'primeng/panelmenu';
import { PasswordModule } from 'primeng/password';
import { PickListModule } from 'primeng/picklist';
import { ProgressBarModule } from 'primeng/progressbar';
import { RadioButtonModule } from 'primeng/radiobutton';
import { RatingModule } from 'primeng/rating';
import { RippleModule } from 'primeng/ripple';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { ScrollTopModule } from 'primeng/scrolltop';
import { SelectButtonModule } from 'primeng/selectbutton';
import { SidebarModule } from 'primeng/sidebar';
import { SlideMenuModule } from 'primeng/slidemenu';
import { SliderModule } from 'primeng/slider';
import { SpinnerModule } from 'primeng/spinner';
import { SplitButtonModule } from 'primeng/splitbutton';
import { StepsModule } from 'primeng/steps';
import { TabMenuModule } from 'primeng/tabmenu';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { TerminalModule } from 'primeng/terminal';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { ToastModule } from 'primeng/toast';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { ToolbarModule } from 'primeng/toolbar';
import { TooltipModule } from 'primeng/tooltip';
import { TreeModule } from 'primeng/tree';
import { TreeTableModule } from 'primeng/treetable';
import { TriStateCheckboxModule } from 'primeng/tristatecheckbox';
import { VirtualScrollerModule } from 'primeng/virtualscroller';
// </primeng-modules>
import { WalkWinRoutingModule } from './walkwin.routing';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { LoginComponent } from './components/login/login.component';
import { LogoutComponent } from './components/logout/logout.component';
import { TableCellValueComponent } from './components/table-cell-value/table-cell-value.component';
import { GiftsComponent } from './components/gifts/gifts.component';
import { GiftFormComponent, DialogGiftFormComponent } from './components/gifts/gift-form/gift-form.component';
import { GiftDetailsComponent, DialogGiftDetailsComponent } from './components/gifts/gift-details/gift-details.component';
import { QuestionsComponent } from './components/questions/questions.component';
import { QuestionFormComponent, DialogQuestionFormComponent } from './components/questions/question-form/question-form.component';
import { QuestionDetailsComponent, DialogQuestionDetailsComponent } from './components/questions/question-details/question-details.component';



import { MembersComponent } from './components/members/members.component';
import { MemberDetailsComponent, DialogMemberDetailsComponent } from './components/members/member-details/member-details.component';
import { ArticlesComponent } from './components/articles/articles.component';
import { ArticleFormComponent, DialogArticleFormComponent } from './components/articles/article-form/article-form.component';
import { ArticleDetailsComponent, DialogArticleDetailsComponent } from './components/articles/article-details/article-details.component';
import { ApplicationsComponent } from './components/applications/applications.component';
import { ApplicationFormComponent ,DialogApplicationFormComponent} from './components/applications/application-form/application-form.component';
import { ApplicationDetailsComponent, DialogApplicationDetailsComponent } from './components/applications/application-details/application-details.component';


import { VideosComponent } from './components/videos/videos.component';
import { VideoFormComponent, DialogVideoFormComponent } from './components/videos/video-form/video-form.component';
import { VideoDetailsComponent, DialogVideoDetailsComponent } from './components/videos/video-details/video-details.component';

import { CategoriesComponent } from './components/categories/categories.component';
import { CategoryFormComponent, DialogCategoryFormComponent } from './components/categories/category-form/category-form.component';
import { CategoryDetailsComponent, DialogCategoryDetailsComponent } from './components/categories/category-details/category-details.component';
import { competitionsComponent } from './components/competition/competition.component';
import { CompetitionFormComponent, DialogCompetitionFormComponent } from './components/competition/competition-form/competition-form.component';
import { CompetitionDetailsComponent, DialogCompetitionDetailsComponent } from './components/competition/competition-details/competition-details.component';
import { ListComponent } from './components/list/list.component';
import { LocationComponent } from './components/location/location.component';
import { StatsComponent } from './components/stats/stats.component';
import { ChartsModule } from 'ng2-charts';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';


@NgModule({
  declarations: [
    SafeHtmlPipe,
    DashboardComponent,
    LoginComponent,
    LogoutComponent,
    TableCellValueComponent,
    competitionsComponent,
    CompetitionFormComponent,
    DialogCompetitionFormComponent,
    CompetitionDetailsComponent,
    DialogCompetitionDetailsComponent,
    GiftsComponent,
    QuestionsComponent,

    ListComponent,
    LocationComponent,
    GiftFormComponent,
    DialogGiftFormComponent,
    GiftDetailsComponent,
    DialogGiftDetailsComponent,

    QuestionFormComponent,
    DialogQuestionFormComponent,
    QuestionDetailsComponent,
    DialogQuestionDetailsComponent,
    MembersComponent,
    MemberDetailsComponent,
    DialogMemberDetailsComponent,
    ArticlesComponent,
    ArticleFormComponent,
    DialogArticleFormComponent,
    ArticleDetailsComponent,
    DialogArticleDetailsComponent,
    ApplicationsComponent,
    ApplicationFormComponent,
    DialogApplicationFormComponent,
    ApplicationDetailsComponent,
    DialogApplicationDetailsComponent,
    VideosComponent,
    VideoFormComponent,
    DialogVideoFormComponent,
    VideoDetailsComponent,
    DialogVideoDetailsComponent,
    CategoriesComponent,
    CategoryFormComponent,
    DialogCategoryFormComponent,
    CategoryDetailsComponent,
    DialogCategoryDetailsComponent,
    StatsComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    LeafletModule,

    TranslateModule.forRoot({
      defaultLanguage: 'fr',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    CKEditorModule,
    WalkWinRoutingModule,
    AccordionModule,
    AutoCompleteModule,
    BreadcrumbModule,
    ButtonModule,
    CalendarModule,
    CardModule,
    CarouselModule,
    ChartModule,
    CheckboxModule,
    ChipsModule,
    CodeHighlighterModule,
    ConfirmDialogModule,
    ColorPickerModule,
    ContextMenuModule,
    DataViewModule,
    DialogModule,
    DropdownModule,
    DynamicDialogModule,
    EditorModule,
    FieldsetModule,
    FileUploadModule,
    FocusTrapModule,
    FullCalendarModule,
    GalleriaModule,
    InplaceModule,
    InputMaskModule,
    InputNumberModule,
    InputSwitchModule,
    InputTextModule,
    InputTextareaModule,
    LightboxModule,
    ListboxModule,
    MegaMenuModule,
    MenuModule,
    MenubarModule,
    MessageModule,
    MessagesModule,
    MultiSelectModule,
    OrderListModule,
    OrganizationChartModule,
    OverlayPanelModule,
    PaginatorModule,
    PanelModule,
    PanelMenuModule,
    PasswordModule,
    PickListModule,
    ProgressBarModule,
    RadioButtonModule,
    RatingModule,
    RippleModule,
    ScrollPanelModule,
    ScrollTopModule,
    SelectButtonModule,
    SidebarModule,
    SlideMenuModule,
    SliderModule,
    SpinnerModule,
    SplitButtonModule,
    StepsModule,
    TableModule,
    TabMenuModule,
    TabViewModule,
    TerminalModule,
    TieredMenuModule,
    ToastModule,
    ToggleButtonModule,
    ToolbarModule,
    TooltipModule,
    TreeModule,
    TreeTableModule,
    TriStateCheckboxModule,
    VirtualScrollerModule,
    ChartsModule

  ],
  exports: [
    SafeHtmlPipe
  ],
  providers: [
    ConfirmationService,
    DialogService,
    MessageService,
  ],
})
export class WalkWinModule { }

